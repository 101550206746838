import Api from '../classes/Api';

export default class AuthFacebook
{
    
    login(data) {
        return new Promise((resolve, reject) => {
            this.askForPermission()
                .then(() => this.getCurrentUser())
                .then(response => this.handleLogin(response))
                .then(data => {
                    if (data.user) {
                        resolve(data);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => reject(error));
        });
    }
    
    askForPermission(scope = 'public_profile,email') {
        return new Promise((resolve, reject) => {
            FB.login(function(response) {
                if (response.status === 'connected') {
                    resolve(response);
                } else {
                    reject();
                }
            }, { scope: scope });
        });
    }

    getCurrentUser() {
        return new Promise((resolve, reject) => {
            FB.api('/me?fields=email,first_name,last_name,picture,cover', function(response) {
                resolve(response);
            });
        });
    }

    handleLogin(data) {
        return new Api().post('login-facebook', data);
    }

    handleFacebookEmailLogin(data) {
        return new Api().post('login-facebook/email', data);
    }

}