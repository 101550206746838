window.Stars = (value) => {
    return {
        value: value,
        min: 1,
        max: 5,
        get() {
            return [
                ...Array(this.full()).fill('full'),
                ...Array(this.half()).fill('half'),
                ...Array(this.empty()).fill('empty')
            ];
        },
        full() {
            return parseInt(this.value);
        },
        half()
        {
            let parts =  (this.value + "").split(".");
            if ( parts[1] ) {
                return 1;
            }
            return 0;
        },
        empty()
        {
            if ( this.value == this.max ) {
                return 0;
            }
            return this.max - ( this.full() + this.half() );
        }
    }

}