import Conversion from 'Base/classes/Conversion';
import Api from 'Base/classes/Api';

window.PhoneModal = (professionalId) => {
    return {
        professionalId,
        opened: false,
        open(event)
        {
            this.opened = true;
            new Conversion().professionalPhoneClicked(this.professionalId);
            new Api().post(event.detail.url);
        },
        close()
        {
            this.opened = false;
        }
    }

}