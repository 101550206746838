export default class Api
{
    
    constructor(localized = false)
    {
        this.localized = localized;
    }

    notLocalized()
    {
        this.localized = false;
        return this;
    }

    localize()
    {
        this.localized = true;
        return this;
    }

    getHeaders()
    {
        return {
            "Accept": "application/json",
            "Accept-Language": window.current.locale,
            'content-type': 'application/json;charset=UTF-8',
            'X-CSRF-TOKEN': window.getCSRFToken()
        };
    }

    getUrl(url)
    {
        return url.startsWith('http') ? url : `${this.getBaseUrl()}/${url}`;
    }

    getBaseUrl()
    {
        if ( this.localized ) {
            return `${window.base_url}/${window.current.locale_without_country_code}`;
        }
        return window.base_url;
    }

    request(url, data, method = 'GET')
    {
        return new Promise((resolve, reject) => {
            fetch(this.getUrl(url), {
                method: method,
                headers: this.getHeaders(),
                body: JSON.stringify(data)
            }).then((response) => {
                if ( ! response.ok ) {
                    return response.json().then((data) => reject({status: response.status, data: data}));
                }
                
                response.json().then((data) => resolve(data));
            })
        });
    }

    get(url, data)
    {
        return this.request(url, data);
    }

    post(url, data)
    {
        return this.request(url, data, 'POST');
    }

    put(url, data)
    {
        return this.request(url, data, 'PUT');
    }

}