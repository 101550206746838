
import Algolia from 'Base/classes/Algolia';
import Dispatch from 'Base/classes/Dispatch';
import Quote from 'Base/classes/Quote';

export default ({id, text, suggestions, workfield = null, professional = null}) => ({
    id: id,
    workfield: workfield,
    professional: professional,
    text: text,
    autocomplete: {
        focused: false,
        query: '',
        suggestions: suggestions,
        results: [],
    },
    elantis: false,
    loading: false,
    init()
    {
        this.loading = false;
    },
    isValid()
    {
        return this.id && this.workfield;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step', {step: 3});
    },
    save()
    {
        this.loading = true;

        new Dispatch(this, 'quote-next-step', {step: 3});

        new Quote(this.id).step3({
            workfield_id: this.workfield,
            text: this.text,
            elantis: this.elantis
        });
    },
    getResults() {
        if ( this.autocomplete.query == '' ) {
            return this.autocomplete.suggestions;
        }
        return this.autocomplete.results;
    },
    searchWorkfields() {
        if ( ! this.autocomplete.query ) {
          return;
        }

        new Algolia('work_fields', window.current.search.attributes.workfield).get(this.autocomplete.query)
            .then(results => {
                this.autocomplete.results = results.slice(0, 10);

                if ( this.professional ) {
                    this.autocomplete.results = results.filter(result => this.autocomplete.suggestions.some(suggestion => suggestion.id == result.id)).slice(0, 10);
                }
            });
    },
    setWorkfield(workfield) {
        console.log(workfield);
        this.autocomplete.query = workfield.title;
        this.workfield = workfield.id;
        this.autocomplete.results = [];
    },
    autocompleteFocus() {
        this.autocomplete.focused = true;
    },
    autocompleteBlur() {
        this.$nextTick(() => {
            this.autocomplete.focused = false;
        })
    }
});