
import Errors from 'Base/classes/Errors';
import Dispatch from 'Base/classes/Dispatch';
import Auth from 'Base/classes/Auth';
import Quote from 'Base/classes/Quote';


export default (id) => ({
    id: id,
    user: {
        email: null,
        password: null
    },
    loading: false,
    errors: new Errors(),
    init()
    {
        this.user.email = this.$parent.user.email;
    },
    isValid()
    {
        return this.user.email && this.user.password;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step');
    },
    save()
    {
        this.loading = true;
        this.errors.clear();
        
        new Auth().login(this.user)
            .then((response) => {
                new Quote(this.id).user(response.user)
                    .then(() => {
                        new Dispatch(this, 'quote-next-step', {step: 'login', user: response.user})
                    });
            })
            .catch((response) => {
                this.errors.record(response.data.errors);
                this.loading = false;
            });
    },
    getInputBorderColor(name)
    {
        return this.errors.has(name) ? 'border-color: #EF4444' : '';
    }
});