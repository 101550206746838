import Prediction from './GoogleAutocompletePrediction';

export default class GoogleAutocomplete {

    constructor(config = {types: ['(regions)']})
    {
        this.config = config;
        this.results = [];
        this.autocomplete = new google.maps.places.AutocompleteService();
    }
    
    search(query = '', withDetails = false)
    {
        return new Promise((resolve, reject) => {
            if ( query == '' ) {
                console.error('No query to search on.');
                return reject([]);
            }

            this.autocomplete.getPlacePredictions({
                input: query,
                types: ['(regions)'],
                language: window.current.locale_without_country_code,
                componentRestrictions: { country: window.current.country_code }
            }, async (predictions, status) => {
                if (status != google.maps.places.PlacesServiceStatus.OK) {
                    console.error('No results found:', status);
                    reject([]);
                }

                predictions = await this.setPredictions(predictions, withDetails);
                console.log(JSON.parse(JSON.stringify(predictions)));
                resolve(predictions);
            });
        });
    }

    async setPredictions(array, withDetails = false)
    {
        let predictions = array.map(data => new Prediction(data));
        
        for ( let i = 0; i < predictions.length; i++ ) {
            predictions[i] = await predictions[i].init();
        }

        if ( ! withDetails ) {
            return predictions;
        }
        
        for ( let i = 0; i < predictions.length; i++ ) {
            predictions[i].details = await predictions[i].details();
        }
        return predictions;
    }

}