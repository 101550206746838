export default class Breakpoint {
	current() {
		return window.getComputedStyle(document.body, ':before').content.replace(/\"/g, '');
	}

	is(breakpoints = []) {
		if ( typeof breakpoints == 'string' ) breakpoints = [breakpoints];

		for ( let i = 0; i < breakpoints.length; i++ ) {
			if ( this.current() == breakpoints[i] ) {
				return true;
			}
		}

		return false;
	}

	isNot(breakpoints = []) {
		return ! this.is(breakpoints);
	}	
}