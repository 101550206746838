import AuthFacebook from 'Base/classes/AuthFacebook';

window.FacebookLogin = () => {
    return {
        loading: false,
        login() {
            this.loading = true;
            
            new AuthFacebook().login()
                .then(data => {
                    window.Toasteo.success(data.message);

                    return redirectTo(data.redirect);
                })
                .catch(error => {
                    this.loading = false;
                });
        }
    }

}