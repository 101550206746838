import Api from '../classes/Api';

export default class Auth
{

    user()
    {
        return new Api().localize().get(`user`);
    }

    checkEmail(email)
    {
        return new Api().localize().post(`check-email`, {email: email});
    }

    login(data)
    {
        return new Promise((resolve, reject) => {
            new Api().localize().post(`login`, data)
                .then(response => {
                    window.replaceCSRFToken(response.token);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    register(data)
    {
        return new Promise((resolve, reject) => {
            new Api().localize().post(`register`, data)
                .then(response => {
                    window.replaceCSRFToken(response.token);
                    window.current.user = response.user;
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    addPhoneNumber(phone, phone_country = 'BE')
    {
        return new Api().localize().put(`account/phone`, {phone, phone_country})
    }

    sendConfirmationSMS()
    {
        return new Api().post(`confirmable/phone`);
    }

    confirmPhoneNumber(token)
    {
        return new Api().post(`confirmable/phone/token/${token}`);
    }

    hasConfirmedPhone()
    {
        return new Promise((resolve, reject) => {
            new Api().localize().post(`register`, data)
                .then(response => {
                    window.replaceCSRFToken(response.token);
                    window.current.user = response.user;
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

}