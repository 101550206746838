import Dispatch from 'Base/classes/Dispatch';
import Errors from 'Base/classes/Errors';
import Auth from 'Base/classes/Auth';

export default (id) => ({
    id: id,
    user: null,
    token: '',
    smsSent: false,
    errors: new Errors(),
    loading: false,
    init()
    {
        this.user = this.$parent.user;
        this.sendSMS();
    },
    isValid()
    {
        return this.token;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step');
    },
    sendSMS()
    {
        this.loading = true;

        new Auth().sendConfirmationSMS()
            .then(() => {
                this.smsSent = true;
                this.loading = false;
            })
    },
    save()
    {
        this.loading = true;

        new Auth().confirmPhoneNumber(this.token)
            .then((response) => {
                new Dispatch(this, 'quote-next-step', {step: 'confirm-phone', user: response.user});
            })
            .catch(error => {
                this.errors.record({token: [error.data.message]})
                this.loading = false;
            });
    }
});