import Api from 'Base/classes/Api';

window.Ad = ({id = null, space = null}) => {
    return {
        space: space,
        id: id,
        instance: null,
        loading: false,
        ad: null,
        type: null,

        getAdById(id)
        {
            new Api().get(`ads/${id}`)
                .then((response) => {
                    this.displayAd(response.data.ad, response.data.type);
                })
                .catch((error) => {
                    if ( error.response.data && error.response.data.message ) {
                        console.log(error.response.data.message);
                    }
                });
        },
        getAdBySpace(space)
        {
            new Api().get(`ads/spaces/${space}`)
                .then(({ad, type}) => {
                    this.displayAd(ad, type);
                })
                .catch((error) => {
                    console.error(error);
                    if ( error.response.data && error.response.data.message ) {
                        console.log(error.response.data.message);
                    }
                });
        },
        displayAd(ad, type)
        {
            if ( type == 'script' ) {
                return this.displayScriptAd(ad);
            }
            this.ad = ad;
            this.type = type;
        },
        displayScriptAd(ad)
        {
            var scriptContainer = document.getElementById(this.instance);
            scriptContainer.innerHTML = ad.content;
        },
        addClick()
        {
            new Api().get(`ads/${this.ad.id}/click`);
        },
        init() {            
            this.instance = 'ad-'+Math.random(64).toString();

            if ( this.id ) {
                this.getAdById(this.id);
            }

            if ( this.space ) {
                this.getAdBySpace(this.space);
            }
        }
    }

}