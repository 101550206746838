import Message from 'Base/classes/ConversationMessage';
import UploadedFile from 'Base/classes/UploadedFile';
import UploadedImage from 'Base/classes/UploadedImage';
import ResizableTextarea from 'Base/classes/ResizableTextarea';

import * as dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr');
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);

window.Conversation = (data) => {
    return {
        data: data,
        newMessage: null,
        sidebar: {
            opened: false,
            images: [],
            files: []
        },
        textarea: null,
        init()
        {
            this.resetMessage();
            this.scrollToBottom();
        },
        resetMessage()
        {
            this.newMessage = {
                loading: false,
                text: '',
                files: [],
                images: []
            };

            this.$nextTick(() => {
                this.textarea = new ResizableTextarea({id: 'account-conversation-new-message-input'});
                this.textarea.init();
            });
        },
        processFile(processor, file)
        {
            return new Promise((resolve) => {
                processor.check(file)
                    .then(() => processor.get(file))
                    .then(data => resolve(data));
            })
        },
        addImages(images)
        {
            console.log(images);
            for ( let i = 0; i < images.length; i++ ) {
                this.processFile(new UploadedImage, images[i])
                    .then(data => {
                        this.newMessage.images.push(data);
                        this.resetInput('account-conversation-image-input');
                    });
            } 
        },
        removeImage(index)
        {
            this.images.splice(index, 1);
        },
        addFiles(files)
        {
            for ( let i = 0; i < files.length; i++ ) {
                this.processFile(new UploadedFile, files[i])
                    .then(data => {
                        this.newMessage.files.push(data);
                        this.resetInput('account-conversation-file-input');
                    });
            } 
        },
        resetInput(id)
        {
            let input = document.getElementById(id);
            input.value = '';
            if ( input.value ) {
                input.type = "text";
                input.type = "file";
            }
        },
        addFilesFromSidebar(files)
        {
            let promises = [];
            for ( let i = 0; i < files.length; i++ ) {
                promises.push( this.processFile(new UploadedFile, files[i]) );
            } 

            Promise.all(promises).then((files) => {
                this.sidebar.files = files;
            })
        },
        removeFileFromSidebar(index)
        {
            this.sidebar.files.splice(index, 1);
        },
        submit()
        {
            if ( this.newMessage.loading ) {
                return;
            }

            this.newMessage.loading = true;

            this.storeMessage(this.newMessage)
                .then(() => {
                    this.resetMessage();
                });
        },
        storeMessage(data)
        {
            return new Promise((resolve) => {
                new Message(this.data.conversation.id, data).store()
                    .then(response => response.json())
                    .then(response => {
                        this.addMessage(response.model);
                        this.scrollToBottom();
                        resolve();
                    });
            });
        },
        addMessage(message)
        {
            this.data.conversation.messages.push(message);

            for ( let i = 0; i < message.files.length; i++ ) {
                this.data.conversation.files.push(message.files[i]);
            }
            
            for ( let i = 0; i < message.images.length; i++ ) {
                this.data.conversation.images.push(message.images[i]);
            }
        },
        scrollToBottom()
        {
            this.$nextTick(() => {
                var container = document.getElementById("account-conversation-messages");
                container.scrollTop = container.scrollHeight;
            });
        },
        previousMessageWasSentYesterday(index)
        {
            if ( index == 0 ) {
                return true;
            }

            let previousMessageDate = dayjs(this.data.conversation.messages[index - 1].created_at, 'YYYY-MM-DD HH:mm:ss');
            let currentMessageDate = dayjs(this.data.conversation.messages[index].created_at, 'YYYY-MM-DD HH:mm:ss');

            return previousMessageDate.isBefore(
                currentMessageDate.startOf('day')
            );
        },
        messageDate(message, todayText)
        {
            return dayjs(message.created_at).isToday() ? todayText : message.readable_date;
        }
    }
}