import Adwords from 'Base/classes/Conversions/Adwords';
import Analytics from 'Base/classes/Conversions/Analytics';
import Facebook from 'Base/classes/Conversions/Facebook';

export default class Conversion
{

    newQuote({quote, professionalId = null})
    {
        console.log('Conversion [newQuote] logged', quote, professionalId);
        let method = quote.direct ? 'newDirectQuote' : 'newGlobalQuote';
        new Adwords()[method]({quote, professionalId});
        new Analytics()[method]({quote, professionalId});
        new Facebook()[method]({quote, professionalId});
    }

    newConversation(conversation)
    {
        console.log('Conversion [newConversation] logged', conversation);
        new Adwords().newConversation(conversation);
        new Analytics().newConversation(conversation);
        new Facebook().newConversation(conversation);
    }

    professionalPhoneClicked(professionalId)
    {
        console.log('Conversion [professionalPhoneClickedEvent] logged', professionalId);
        new Adwords().professionalPhoneClicked(professionalId);
        new Analytics().professionalPhoneClicked(professionalId);
        new Facebook().professionalPhoneClicked(professionalId);
    }

}