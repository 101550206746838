
import Errors from 'Base/classes/Errors';
import Auth from 'Base/classes/Auth';
import Api from 'Base/classes/Api';
import Dispatch from 'Base/classes/Dispatch';

export default (id) => ({
    id: id,
    email: '',
    loading: false,
    errors: new Errors(),
    init()
    {
        //
    },
    isValid()
    {
        return this.id && this.email;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step', {step: 4});
    },
    save()
    {
        this.loading = true;
        this.errors.clear();

        new Auth().checkEmail(this.email)
            .then((data) => {
                new Dispatch(this, 'quote-next-step', {step: 4, user: {email: this.email}, exists: data.exists});
            })
            .catch((response) => {
                console.log(response);
                this.errors.record(response.data.errors);
                this.loading = false;
            });
    },
    getInputBorderColor(name)
    {
        return this.errors.has(name) ? 'border-color: #EF4444' : '';
    }
});