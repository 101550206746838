import Api from 'Base/classes/Api';
import Dispatch from 'Base/classes/Dispatch';
import Auth from 'Base/classes/Auth';
import Quote from 'Base/classes/Quote';
import Conversion from 'Base/classes/Conversion';

export default ({id, workfield = null, professional = null}) => ({
    id: id,
    workfield: workfield,
    professional: professional,
    user: {
        email: null,
    },
    step: 1,
    stepNumber()
    {
        if ( isNaN(this.step) ) {
            return 4;
        }
        return this.step;
    },
    stepPercentage()
    {
        return 100 / 4 * this.step;
    },
    previousStep()
    {
        if ( this.step == 2 ) {
            return this.step = 1;
        }

        if ( this.step == 3 ) {
            return this.step = 2;
        }

        if ( this.step == 4 ) {
            return this.step = 3;
        }

        if ( this.step == 'auth' ) {
            return this.step = 4;
        }
    },
    nextStep({detail})
    {
        console.log('Going to next step', detail, this.step);

        if ( detail.step == 1 ) {
            return this.step = 2;
        }

        if ( detail.step == 2 ) {
            return this.step = 3;
        }

        if ( detail.step == 3 ) {
            new Auth().user()
                .then(({user}) => {
                    new Quote(this.id).user(user)
                        .then(() => {
                            this.authed(user);
                        });
                })
                .catch(() => {
                    this.step = 4;
                })
        }
        
        if ( detail.step == 4 ) {
            this.user = detail.user;
            
            if ( detail.exists ) {
                return this.step = 'login';
            }
            return this.step = 'register';
        }

        if ( detail.step == 'login' || detail.step == 'register' ) {
            return this.authed(detail.user);
        }

        if ( detail.step == 'set-phone' || detail.step == 'confirm-phone') {
            return this.authed(detail.user);
        }
    },
    authed(user)
    {         
        console.log('Authed');
        this.user = user;

        if ( this.user.is_confirmed_by_phone || ! this.requireConfirmedPhoneNumber() ) {
            return this.success();
        }

        if ( ! this.user.phone ) {
            return this.step = 'set-phone';
        }
        
        this.step = 'confirm-phone';
    },
    requireConfirmedPhoneNumber()
    {
        return this.professional !== null;
    },
    success()
    {
        this.step = 'success';

        new Conversion().newQuote({quote: {
            id: this.id,
            direct: this.professional ? true : false
        }, professionalId: this.professional});
    }

});