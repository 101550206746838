import Query from '../classes/Search';
import GoogleAutocomplete from '../classes/GoogleAutocomplete';

window.Search = ({optimized = true, page = 1, distance = 100, workfields = [], orderBy = 'popularity', location = null, cachedSearch = null}) => {
    return {
        cachedSearch: cachedSearch,
        optimized: optimized,
        loading: true,
        search: null,
        autocomplete: new GoogleAutocomplete(),
        filters: {
            page: page,
            distance: distance,
            workfields: workfields,
            orderBy: orderBy,
            location: location !== null ? location : {},
            locale: window.current.locale
        },
        location: {
            search: '',
            results: []
        },
        dropdown: {
            order: false,
            workfield: false,
            address: false
        },
        init()
        {
            if ( this.cachedSearch ) {
                this.search = this.cachedSearch;
                this.loading = false;
                window.history.pushState({}, "",  this.cachedSearch.url);
                return;
            }

            this.get();
        },
        hasLocation()
        {
            return this.filters.location.city !== undefined && this.filters.location.city !== null;
        },

        resetLocationFilters()
        {
            this.filters.location = {};
            this.location.search = '';
            this.get(1);
        },

        hideDropdownLocation()
        {
            this.dropdown.location = false;
        },

        showDropdownLocation()
        {
            this.dropdown.location = true;
            this.$nextTick(() => this.$refs.locationFilterInput.focus());
        },

        locationSearch(location = null)
        {
            if(location)
                {
                    this.location.search = location;
                }

            this.location.results = [];
            this.autocomplete.search(this.location.search)
                .then(results => this.location.results = results)
                .catch(() => this.location.results = []);
        },
        locationPrediction()
        {
            return (this.location.results.length > 0 && this.location.search.length > 2 ) ? this.location.results[0] : null;
        },
        setLocation(location)
        {
            location.details()
                .then((data) => {
                    console.log(data);
                    this.filters.location = data;
                    this.get(1);
                    this.dropdown.location = false;
                })
        },
        toggleWorkfield(id)
        {
            this.filters.workfields = this.workfieldIsSelected(id) ? this.filters.workfields.filter(i => i !== id) : [ ...this.filters.workfields, id ];
            this.get(1);
            this.dropdown.workfield = false;
        },
        workfieldIsSelected(id)
        {
            return this.filters.workfields.includes(id);
        },
        setOrder(order)
        {
            this.filters.orderBy = order;
            this.get(1);
            this.dropdown.order = false;
        },
        setPage(page)
        {
            this.get(parseInt(page));
        },
        get(page = this.filters.page)
        {
            if ( this.search !== null ) {
                document.getElementById('search-results-list').scrollIntoView();
            }

            if ( document.getElementById('search-cached-results') ) {
                var elem = document.getElementById('search-cached-results');
                elem.parentNode.removeChild(elem);
            }

            this.cachedSearch = null;
            this.filters.page = page;
            this.loading = true;

            new Query(this.optimized).get(this.filters)
                .then(response => response.json())
                .then(response => {
                    this.search = response;
                    this.loading = false;
                    window.history.pushState({}, "",  response.url);
                    window.blazy.revalidate();
                });
        }
    }

}