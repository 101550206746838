import Algolia from 'Base/classes/Algolia';
import Breakpoint from 'Base/breakpoint';
import Dispatch from 'Base/classes/Dispatch';

window.SearchInput = (suggestions = []) => {
    return {
        query: '',
        postalCode : '',
        workfield : null,
        results: [],
        suggestions: suggestions,
        loading: false,
        focused: false,
        submitting: false,
        hasResults() {
            return this.getResults().length > 0;
        },
        getResults() {
            if ( this.query == '' ) {
                return this.suggestions;
            }
            return this.results;
        },
        search() {
            if ( ! this.query ) {
              return;
            }

            this.loading = true;
            new Algolia('work_fields', window.current.search.attributes.workfield).get(this.query)
                .then(results => {
                    this.results = results.slice(0, 10);
                    this.loading = false;
                });
        },
        submit()
        {
            if ( this.submitting ) {
                return;
            }

            this.submitting = true;
            let parameter = window.str_to_slug(this.query);

            if ( this.workfield ) {
                this.query = this.workfield.title;
                parameter = this.workfield.search_url_parameter;
            }


            if(this.postalCode)
                {
                    return window.location.href = window.current.routes.search.city.replace(':workfield', parameter).replace(':city', this.postalCode);
                }

            return window.location.href = window.current.routes.search.show.replace(':workfield', parameter);
        },
        closeModal()
        {
            this.focused = false;
            new Dispatch(this, 'modal-closed');
        },
        focus()
        {
            this.focused = true;
            if ( new Breakpoint().is(['default', 'sm']) ) { 
                new Dispatch(this, 'modal-opened');
                this.$nextTick(() => {
                    this.$refs.responsive_search_bar_input.focus()
                });  
            } 
        },
        removeFocus()
        {
            if ( new Breakpoint().isNot(['default', 'sm']) ) { 
                this.focused = false;
            } 
        },
        setWorkfield(workfield)
        {
            this.workfield = workfield;
            this.query = workfield.title;
            this.focused = false;
        },

        setWorkfieldWithoutClosing(workfield)
        {
            this.workfield = workfield;
            this.query = workfield.title;
        }

    }

}