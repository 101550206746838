async function getPros(workfieldId, csrf) {
  document.getElementById("loaderList").style.display = "flex";
  document.getElementById("loaderMap").style.display = "flex";

  const response = await fetch("/fr/entreprises", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Csrf-Token": csrf,
    },
    method: "POST",
    body: JSON.stringify({
      page: 1,
      workfields: [workfieldId],
      orderBy: "popularity",
      boundaries: [
        window.myMap.getBounds().getNorthEast(),
        window.myMap.getBounds().getSouthWest(),
      ],
      locale: "be-fr",
      optimized: "1",
    }),
  });

  document.getElementById("loaderList").style.display = "none";
  document.getElementById("loaderMap").style.display = "none";

  return await response.json();
}

async function search(workfieldId, baseUrl) {
  for (var i = 0; i < window.markers.length; i++) {
    window.markers[i].setMap(null);
  }
  window.markers.length = 0;

  var data = await getPros(workfieldId, baseUrl);

  document.getElementById("mapSearchResults").innerHTML = data.view;

  data.data.forEach((pro, index) => {
    var order = index + 1;
    var marker = new google.maps.Marker({
      position: {
        lat: pro.latitude,
        lng: pro.longitude,
      },
      icon: {
        url: `${baseUrl}/images/professionalDot${order}.png`,
        scaledSize: { width: 20, height: 20 },
      },
      map: window.myMap,
      title: pro.company,
    });

    google.maps.event.addListener(marker, "click", function () {
      if (window.selectedPros.includes(pro.id)) {
        marker.setIcon({
          url: `${baseUrl}/images/professionalDot${order}.png`,
          scaledSize: { width: 20, height: 20 },
        });

        document.getElementById(`professional${pro.id}`).style.border =
          "1px solid #d1d5db";
        document
          .getElementById(`professionalDot${pro.id}`)
          .classList.add("invert-[.85]");
        const index = window.selectedPros.indexOf(pro.id);
        window.selectedPros.splice(index, 1);
      } else {
        marker.setIcon({
          url: `${baseUrl}/images/professionalDotSlct${order}.png`,
          scaledSize: { width: 20, height: 20 },
        });

        document.getElementById(`professional${pro.id}`).style.border =
          "3px solid #000000";
        document
          .getElementById(`professionalDot${pro.id}`)
          .classList.remove("invert-[.85]");
        document
          .getElementById(`professional${pro.id}`)
          .scrollIntoView({ block: "center", behavior: "smooth" });
        window.selectedPros.push(pro.id);
      }
    });

    window.markers.push(marker);
  });
}

async function initMap() {
  const myLatLng = { lat: 50.85, lng: 4.462 };
  window.myMap = new google.maps.Map(document.getElementById("map"), {
    zoom: 10,
    center: myLatLng,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: [
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.neighborhood",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        stylers: [
          {
            color: "#cccccc",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#cccccc",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.local",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  });

  google.maps.event.addListener(window.myMap, "dragend", function () {
    document.getElementById("refresherButton").style.display = "block";
    // search(); // uncomment this line if you want refresh pro on drag
  });

  google.maps.event.addListenerOnce(window.myMap, "tilesloaded", function () {
    search();
  });

  // await search();
}

window.initMap = initMap;

window.searchPros = search;
window.markers = [];
window.selectedPros = [];
