import Conversion from 'Base/classes/Conversion';
import Api from 'Base/classes/Api';

window.ProfessionalProfile = (professionalId) => {
    return {
        professionalId,
        openWebsite({website, viewUrl})
        {
            new Api().post(viewUrl);
            window.open(website, '_blank');
        }
    }

}