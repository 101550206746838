import lottie from 'lottie-web';

/**
 * Transforming blade value to boolean.
 * @param {number} value Value to transform
 * @returns bool
 */
const toBool = value => value === 1;

/**
 * Injecting lottie animation.
 * @param {{container: string, icon: string, autoplay: number, loop: number}}    
 * @returns 
 */
const Lottie = ({
    container,
    icon,
    autoplay = 1,
    loop = 0
}) => ({
    container,
    icon,
    loop: toBool(loop),
    autoplay: toBool(autoplay),
    init() {
        lottie.loadAnimation({
            container: document.getElementById(this.container),
            renderer: 'svg',
            loop: toBool(loop),
            autoplay: toBool(autoplay),
            path: `${process.env.MIX_SHARED_ASSETS_URL}/${icon}.json`
        });
    }
});

window.Lottie = Lottie;