import Dispatch from 'Base/classes/Dispatch';
import Errors from 'Base/classes/Errors';
import Auth from 'Base/classes/Auth';

export default (id) => ({
    id: id,
    phone_number: '',
    errors: new Errors(),
    loading: false,
    init()
    {
        //
    },
    isValid()
    {
        return this.phone_number;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step');
    },
    save()
    {
        this.loading = true;

        new Auth().addPhoneNumber(this.phone_number)
            .then((response) => {
                new Dispatch(this, 'quote-next-step', {step: 'set-phone', user: response.user});
            })
    }
});