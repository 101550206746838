/**
 * Create Flickity carousel for categories and sub-categories
 */
let sub_categories = document.querySelectorAll('.flickity--sub-categories');
var flickities = [];
sub_categories.forEach(function(subcategory) {
    flickities.push({
        'category_id': subcategory.id,
        'flickity': new Flickity(subcategory, {
            cellAlign: 'left',
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: true,
            // autoPlay: 2000
        })
    });
});

// Method to refresh the flickity when we change category (and it's showed with AlpineJS)
window.refreshFlickity = function(category_id) {
    flickities.forEach(function (subcategory) {
        if (subcategory.category_id == category_id)
            subcategory.flickity.resize()
    })
}