window.redirectTo = function(url, delay = 1500) {
    setTimeout(function() {
        window.location = url;
    }, delay);
}

window.scrollToTop = function(offset = 0) {
    document.body.scrollTop = offset; // For Safari
    document.documentElement.scrollTop = offset;
}

window.replaceUrl = function(url = '', title = '', object = {}) {
    if (window.history.replaceState && url !== '' && typeof url !== undefined) {
        window.history.replaceState(object, title, url);
    }
}

window.openNewTab = function (url) {
    var win = window.open(url, '_blank');
    win.focus();
}

window.addHttp = function(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}

window.getCSRFToken = function() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

window.replaceCSRFToken = function(token) {
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
}

window.randomId = function() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 36);
}

window.revalidateImages = function () {
    if ( window.blazy ) {
        window.blazy.revalidate();
    }
}

window.str_to_slug = function (text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .trim();
}

window.str_slug_trim = function (text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .trim()
}

window.safe_phone = function (phone) {
    return phone.trim().substring(0, phone.length - 2) + '... Afficher';
}

window.safe_email = function (email) {
    return email.trim().substring(0, email.indexOf('@')) + '@xxxxx.xxx';
}

String.prototype.slugToLabel = function () {
    return this.replace(/_/g, ' ').capitalizeWords().replace(' Id', ' ID');
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.capitalizeWords = function () {
    var splitStr = this.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
}

window.insertAtCaret = function(elementId, text) {
    var input = document.getElementById(elementId);
    if ( ! input ) {
        return false;
    }
  
    var scrollPos = input.scrollTop;
    var strPos = 0;
    var br = ((input.selectionStart || input.selectionStart == '0') ?
      "ff" : (document.selection ? "ie" : false));

    if ( br == "ie" ) {
        input.focus();
        var range = document.selection.createRange();
        range.moveStart('character', -input.value.length);
        strPos = range.text.length;
    } else if ( br == "ff" ) {
        strPos = input.selectionStart;
    }
  
    var front = (input.value).substring(0, strPos);
    var back = (input.value).substring(strPos, input.value.length);
    input.value = front + text + back;
    strPos = strPos + text.length;

    if ( br == "ie" ) {
        input.focus();
        var ieRange = document.selection.createRange();
        ieRange.moveStart('character', -input.value.length);
        ieRange.moveStart('character', strPos);
        ieRange.moveEnd('character', 0);
        ieRange.select();
    } else if ( br == "ff" ) {
        input.selectionStart = strPos;
        input.selectionEnd = strPos;
        input.focus();
    }
  
    input.scrollTop = scrollPos;

    return input.value;
}

window.convertMysqlDate = function(date)
{
    let dateTimeParts= date.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
    dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

    return new Date(...dateTimeParts); // our Date object
}