export default class Dispatch
{

    constructor(alpine, name, data = {})
    {
        alpine.$el.dispatchEvent(new CustomEvent(name, {
            detail: data,
            bubbles: true
        }));
    }

}