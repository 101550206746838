window.ImageModal = () => {
    return {
        opened: false,
        image: null,
        open(image)
        {
            this.image = image;
            this.opened = true;
        },
        close()
        {
            this.opened = false;
            this.image = null;
        }
    }

}