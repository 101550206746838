import algoliasearch from 'algoliasearch/lite';

export default class Algolia {

    constructor(index = 'work_fields', searchAttributes = null)
    {
        this.algolia = {};
        this.algolia.client = algoliasearch(window.algolia.id, window.algolia.secret);
        this.algolia.index = this.algolia.client.initIndex(`${window.algolia.prefix}${index}`);
        this.searchAttributes = searchAttributes;
    }
    
    get(query = '')
    {
        return new Promise((resolve) => {
            this.algolia.index.search(query, this.getParams())
                .then(({hits}) => {
                    resolve(
                        hits.map(item => {
                            if ( item[window.current.locale] ) {
                                
                                for ( const [key, value] of Object.entries(item[window.current.locale]) ) {
                                    item[key] = value;
                                }
                            }
                            return item;
                        })
                    );
                });
        });
    }

    getParams()
    {
        let params = {};

        if ( this.searchAttributes ) {
            params.restrictSearchableAttributes = this.searchAttributes.map(item => `${window.current.locale}.${item}`);
        }

        return params;
    }

}