window.Register = () => {
    return {
        loading: false,
        g_recaptcha_response: null,
        register(e)
        {
            this.loading = true;

            e.preventDefault();
            let alpine = this;
            grecaptcha.ready(function() {
                grecaptcha.execute(window.google_recaptcha_key, {action: 'submit'}).then(function(token) {
                    alpine.g_recaptcha_response = token;
                    alpine.$nextTick(() => document.getElementById('alpine-register-form').submit());
                });
            });
        }
    }

}