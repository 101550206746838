export default (id) => ({
    user: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
    },
    init() {
        console.log('init success', this.$parent.user, window.current.user);
        this.user = this.$parent.user;
    },
    isValid() {
        return true;
    }
});