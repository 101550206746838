export default class Analytics {
    
    create({eventCategory = 'Conversion', eventAction, dimensions = {}})
    {
        if ( typeof ga == "undefined" ) {
            console.log('Cannot send event to Adwords without ga');
            return;
        }

        let formattedDimensions = {};
        let dimension = 'dimension' + window.google_analytics.dimensions['url'];
        formattedDimensions[dimension] = window.location.href;
        if ( Object.entries(dimensions).length !== 0 ) {
            for ( let [key, value] of Object.entries(dimensions) ) {
                let dimension = 'dimension' + window.google_analytics.dimensions[key];
                formattedDimensions[dimension] = '' + value; // Analytics requires a string, this will cast a model id to a string.
            }
        }

        ga('send', {
            ...{
                hitType: 'event',
                eventCategory: eventCategory,
                eventAction: eventAction,
            },
            ...formattedDimensions
        });
    }
    
    newQuote({quote, eventAction, professionalId})
    {
        this.create({
            eventAction: eventAction,
            dimensions: {
                'professional-id': professionalId,
                'object-id': quote.id,
                'user-id': quote.user_id
            }
        });
    }
    
    newGlobalQuote({quote, professionalId})
    {
        this.newQuote({
            eventAction: 'Devis global',
            quote: quote,
            professionalId: professionalId
        });
    }
    
    newDirectQuote({quote, professionalId})
    {
        this.newQuote({
            eventAction: 'Devis direct',
            quote: quote,
            professionalId: professionalId
        });
    }

    newConversation(conversation)
    {
        this.create({
            eventAction: 'Conversation',
            dimensions: {
                'user-id': conversation.from_id,
                'professional-id': conversation.to_id,
                'object-id': conversation.id
            }
        });
    }

    professionalPhoneClicked(id)
    {
        this.create({
            eventAction: 'Click téléphone professionnel',
            dimensions: {
                'professional-id': id
            }
        });
    }


}