import GoogleAutocomplete from '../classes/GoogleAutocomplete';

window.SearchQuoteBox = (workfield, query = '') => {
    return {
        workfield: workfield,
        query: query,
        autocomplete: new GoogleAutocomplete(),
        results: [],
        search()
        {
            this.results = [];
            this.autocomplete.search(this.query)
                .then(results => this.results = results)
                .catch(() => this.results = []);
        },
        prediction()
        {
            return (this.results.length > 0 && this.query.length > 2 ) ? this.results[0] : null;
        },
        async redirect()
        {
            await this.search();
            return setTimeout(() => {
                return window.location.href = `${window.current.routes.quote}?workfield=${this.workfield}&postalCode=${this.prediction().value()}`;
            }, 200);
           
        }
    }

}