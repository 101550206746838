export default class Query {

    constructor(optimized = false)
    {
        this.optimized = optimized;
    }
    
    get(filters = {})
    {
        return fetch(window.current.routes.search.request, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({...filters, ...{optimized: this.optimized}})
        });
    }

}