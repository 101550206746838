import Blazy from 'blazy';
import 'alpine-magic-helpers';
import 'alpinejs';
import Siema from 'siema';

window.addEventListener('load', () => {
    
    window.blazy = new Blazy();

    if ( document.querySelector('.siema--global-reviews') ) {
        let mySiema = new Siema({
            selector: '.siema--global-reviews',
            loop: true,
            perPage: {
                768: 3
            }
        });
        document.querySelector('.siema--global-reviews-prev').addEventListener('click', () => mySiema.prev());
        document.querySelector('.siema--global-reviews-next').addEventListener('click', () => mySiema.next());
    }

});

window.addEventListener('modal-opened', () => {
    console.log('Hey a modal was opened!');
    document.body.classList.add('overflow-y-hidden');
});

window.addEventListener('modal-closed', () => {
    console.log('Hey a modal was closed!');
    document.body.classList.remove('overflow-y-hidden');
});

window.replaceCSRFToken = function(token) {
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
}