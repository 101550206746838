import UploadedFile from "./UploadedFile";

export default class UploadedImage extends UploadedFile
{

    constructor({
        extensions = ['jpg', 'jpeg', 'png', 'gif'],
        mimes = ['image/jpeg', 'image/gif', 'image/png'],
        dimensions = {
            height: 1200,
            width: 1200
        },
        size = 10000000,
    } = {}) {
        super({});
        this.rules = {extensions, mimes, dimensions, size};
    } 
    
    get(file)
    {    
        return new Promise((resolve) => {
            // Read Image
            var reader = new FileReader();
            var vue = this;
            console.log('Reading image');
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = (imageEvent) => {
                    console.log('Checking image orientation');
                    this.checkImageOrientation(image)
                        .then(({canvas, image}) => {
                    
                            console.log('Resize image');
                            this.resizeImage(canvas, image)
                                .then(image => {

                                    console.log('Return image');
                                    resolve({
                                        source: image,
                                        file: file,
                                        name: file.name,
                                        description: '',
                                        is_focused: false,
                                    });
                                });
                        });
                };
            };
            reader.readAsDataURL(file);
        });
    }
    
    checkImageOrientation(image)
    {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;

            window.EXIF.getData(image, function() {
                var orientation = window.EXIF.getTag(this, "Orientation");
                console.log('Image orientation ' + orientation);

                console.log(canvas, ctx);
                var width = canvas.width;
                var height = canvas.height;

                switch (orientation) {
                    case 2: 
                        console.log('EXIF case 2');
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;

                    case 3: 
                        console.log('EXIF case 3');
                        ctx.transform(-1, 0, 0, -1, width, height ); 
                        break;

                    case 4: 
                        console.log('EXIF case 4');
                        ctx.transform(1, 0, 0, -1, 0, height ); 
                        break;

                    case 5: 
                        console.log('EXIF case 5');
                        canvas.width = height;
                        canvas.height = width;
                        ctx.transform(0, 1, 1, 0, 0, 0); 
                        break;

                    case 6: 
                        console.log('EXIF case 6');
                        canvas.width = height;
                        canvas.height = width;
                        ctx.transform(0, 1, -1, 0, height , 0); 
                        break;

                    case 7: 
                        console.log('EXIF case 7');
                        canvas.width = height;
                        canvas.height = width;
                        ctx.transform(0, -1, -1, 0, height , width); 
                        break;

                    case 8: 
                        console.log('EXIF case 8');
                        canvas.width = height;
                        canvas.height = width;
                        ctx.transform(0, -1, 1, 0, 0, width); 
                        break;

                    default:
                        console.log('Default exif');
                        break;
                }

                resolve({canvas, image});
            });
        });
    }
    
    resizeImage(canvas, image)
    {
        return new Promise((resolve, reject) => {

            var duration = Date.now();
            canvas.getContext('2d').drawImage(image, 0, 0);
            console.log('Current : ' + canvas.width + 'x' + canvas.height);

            let ratio = 0;
            let width = canvas.width;
            let height = canvas.height;
        
            // Check if the current width is larger than the max
            if ( width > this.rules.dimensions.width ) {
                ratio = this.rules.dimensions.width / width;
                width = this.rules.dimensions.width;
                height = height * ratio;
            }

            // Check if current height is larger than max
            if ( height > this.rules.dimensions.height ) {
                ratio = this.rules.dimensions.height / height;
                height = this.rules.dimensions.height;
                width = width * ratio;
            }

            var resizedCanvas = document.createElement("canvas");
            resizedCanvas.height = canvas.height;
            resizedCanvas.width = canvas.width;

            var resizeCtx = resizedCanvas.getContext('2d');
            // Put original canvas contents to the resizing canvas
            resizeCtx.drawImage(canvas, 0, 0);

            console.log('Resizing to ' + width + 'x' + height);
            window.HERMITE.resample_single(resizedCanvas, width, height, true);
            console.log('Resized in ' + Math.round(Date.now() - duration)/1000);
            resolve( resizedCanvas.toDataURL("image/jpeg") );
        });
    }
    
    rotate(uploadedImage, degrees)
    {
        var rotation = 0;
        if ( typeof uploadedImage.rotation !== 'undefined' ) {
            rotation = uploadedImage.rotation;
        }

        // Store counterclockwise values for PHP.
        if ( degrees == 90 ) {
            rotation -= 90;
        } else if ( degrees == 180 ) {
            rotation += 180;
        } else if ( degrees == 270 ) {
            rotation += 90;
        }
        uploadedImage.rotation = rotation;

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");

        var image = new Image();
        image.crossOrigin="anonymous"
        image.src = uploadedImage.source;
        image.onload = function() {
            
            // Set the canvas size according to what the rotated image will be.
            if ( degrees === 180 ) {
                canvas.width = image.width;  
                canvas.height = image.height;
            } else {
                canvas.width = image.height;
                canvas.height = image.width;
            }
            ctx.rotate(degrees * Math.PI / 180);
            
            // Translate the context accordingly.
            if ( degrees === 90 ) {
                ctx.translate(0, -canvas.width);
            } else if ( degrees === 180 ) {
                ctx.translate(-canvas.width, -canvas.height);
            } else if ( degrees === 270 ) {
                ctx.translate(-canvas.height, 0);
            }

            // Add the image.
            ctx.drawImage(image, 0, 0); 

            // Set the new src. 
            uploadedImage.source = canvas.toDataURL();
        };
    }
}