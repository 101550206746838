import form from "./form";
import step1 from "./steps/step1";
import step2 from "./steps/step2";
import step3 from "./steps/step3";
import step4 from "./steps/step4";
import login from "./steps/login";
import register from "./steps/register";
import setPhone from "./steps/set-phone";
import confirmPhone from "./steps/confirm-phone";
import success from "./steps/success";

export default {
    steps: {
        step1,
        step2,
        step3,
        step4,
        login,
        register,
        setPhone,
        confirmPhone,
        success
    },
    form
};