import Siema from 'siema';

window.ProjectModal = () => {
    return {
        loading: true,
        opened: false,
        project: null,
        slider: null,
        open({url})
        {
            this.get(url);
        },
        get(url)
        {
            this.loading = true;
            this.opened = true;

            fetch(url, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
                .then((response) => response.json())
                .then(response => {
                    this.project = response.project;
                    this.loading = false;
                    this.bootSlider();
                });
        },
        bootSlider()
        {
            this.$nextTick(() => {
                // Fix the slider height.
                let container = document.querySelector('.siema--project-modal-container');
                let cells = document.querySelectorAll('.siema--project-modal-cell');
                container.style.height = `${container.clientHeight}px`;
                for ( var i = 0; i < cells.length; i++ ) {
                    cells[i].style.height = `${container.clientHeight}px`;
                }
                
                let templateElement = document.querySelector('.siema--project-modal template');
                console.log(templateElement);
                templateElement.remove();

                this.slider = new Siema({
                    selector: '.siema--project-modal',
                    loop: true,
                    perPage: 1
                })
            });
        },
        close()
        {
            this.opened = false;
            this.project = null;
            this.loading = true;
        }
    }

}