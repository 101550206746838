export default class Place
{
    constructor(data = {})
    {
        this.data = data;
        this.address = {
            street_number: '',
            street_name: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
            latitude: null,
            longitude: null
        };
        this.format();
    }
    
    format()
    {
        if ( ! Array.isArray(this.data.address_components) ) {
            throw Error('Address Components is not an array');
        }
      
        if ( ! this.data.address_components.length ) {
            throw Error('Address Components is empty');
        }

        this.address.latitude = this.data.geometry.location.lat();   
        this.address.longitude = this.data.geometry.location.lng();        

        for ( let i = 0; i < this.data.address_components.length; i++ ) {
            const component = this.data.address_components[i];
      
            if ( this.isStreetNumber(component) ) {
                this.address.street_number = component.long_name;
            }
      
            if ( this.isStreetName(component) ) {
                this.address.street_name = component.long_name;
            }
      
            if ( this.isCity(component) ) {
                this.address.city = component.long_name;
            }
      
            if ( this.isCountry(component) ) {
                this.address.country = component.long_name;
            }
      
            if  (this.isState(component) ) {
                this.address.state = component.long_name;
            }
      
            if ( this.isPostalCode(component) ) {
                this.address.postal_code = component.long_name;
            }
        }

        return this.address;
    }

    isStreetNumber(component)
    {
        return component.types.includes('street_number');
    }
    
    isStreetName(component)
    {
        return component.types.includes('route');
    }
    
    isCity(component)
    {
        return component.types.includes('locality');
    }
    
    isState(component)
    {
        return component.types.includes('administrative_area_level_1');
    }
    
    isCountry(component)
    {
        return component.types.includes('country');
    }
    
    isPostalCode(component)
    {
        return component.types.includes('postal_code');
    }
}