import Place from './GooglePlace';

export default class Prediction
{
    constructor(data = {})
    {
        this.city = '';

        for (const [key, value] of Object.entries(data)) {
            this[key] = value;
        }
    }

    async init()
    {
        await this.setCity();
        return this;
    }

    value()
    {
        if ( this.structured_formatting && this.structured_formatting.main_text ) {
            return this.structured_formatting.main_text;
        }
        return this.description;
    }

    text()
    {
        if ( this.structured_formatting ) {
            return this.structured_formatting.main_text + ' ' + this.structured_formatting.secondary_text.substr(0, this.structured_formatting.secondary_text.indexOf(',')); 
        }
        return this.description.substr(0, this.description.indexOf(',')); 
    }

    async setCity()
    {
        this.city = await this.getCity();
    }

    /** 
     * For some results, Google will find the postal code but no city (somehow...). Ex: 3680 should return Maaseik
     * In those instances, we need to retrieve the latitude/longitude from the place_id, and reverse geocode this location
     * to finally retrieve the city.
     */
    async getCity()
    {
        let city = this.text().substr(this.text().indexOf(' ') + 1); 
        if ( city ) {
            console.log('City found directly:', city);
            return city;
        }
        
        city = await this.getCityFromPlaceId();
        console.log('City found after:', city);
        return city;
    }

    async getCityFromPlaceId()
    {
        let place = await this.details();
        console.log('Place found:', place);
        if ( ! place ) {
            return '';
        }

        let geocoder = new google.maps.Geocoder();
        let response = await geocoder.geocode({location: {lat: place.latitude, lng: place.longitude}});
        if ( ! response.results || ! response.results[0] ) {
            return '';
        }

        for ( var i = 0; i < response.results.length; i++ ) {
            if ( response.results[i].types[0] === "locality" ) {
                return response.results[i].address_components[0].short_name;
            };
        };

        return '';
    }

    details()
    {
        return new Promise((resolve, reject) => {
            let service = new google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails(
                {placeId: this.place_id},
                (place, status) =>
            {
                if ( status !== google.maps.places.PlacesServiceStatus.OK ) {
                    console.log('Could not find place with id', this.place_id, status);
                    return reject();
                }

                return resolve(new Place(place).format());
            });
        });
    }
}