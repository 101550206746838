import Api from '../classes/Api';

export default class Quote
{

    constructor(id)
    {
        this.id = id;
    }

    request(url, data)
    {
        return new Api().localize().put(`quotes/temporary/${this.id}/${url}`, data);
    }

    step1(data)
    {
        return this.request('step-1', data);
    }

    step2(data)
    {
        return this.request('step-2', data);
    }

    step3(data)
    {
        return this.request('step-3', data);
    }

    user(data)
    {
        return this.request('user', data);
    }
}