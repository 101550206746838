export default class Adwords {
    
    create({eventName, extraData = {}})
    {
        if ( typeof gtag == "undefined" ) {
            return;
        }

        if ( ! extraData.user && window.current.user ) {
            extraData.user = window.current.user.id;
        }

        for ( let i = 0; i < window.google_adwords.length; i++ ) {

            this.sendEvent({
                accountId: window.google_adwords[i].id,
                eventId: window.google_adwords[i].events[eventName],
                extraData: extraData
            });

        }
    }

    sendEvent({event = 'conversion', accountId, eventId, extraData = {}})
    {
        if ( typeof gtag == "undefined" ) {
            console.log('Cannot send event to Adwords without gtag');
            return;
        }

        gtag(
            'event',
            event,
            {
                ...{'send_to': 'AW-' + accountId + '/' + eventId},
                ...extraData
            }
        );
    }

    newQuote({quote, eventName, professionalId})
    {
        this.create({
            eventName: eventName,
            professionalId: professionalId,
            user: quote.user_id,
            quote: quote.id
        });
    }
    
    newGlobalQuote({quote, professionalId})
    {
        this.newQuote({
            eventName: 'new-global-quote',
            quote: quote,
            professionalId: professionalId
        });
    }
    
    newDirectQuote({quote, professionalId})
    {
        this.newQuote({
            eventName: 'new-direct-quote',
            quote: quote,
            professionalId: professionalId
        });
    }
    
    newConversation(conversation)
    {
        this.create({
            eventName: 'new-message',
            from: conversation.from_type + '-' + conversation.from_id,
            to: conversation.to_type + '-' + conversation.to_id,
            conversation: conversation.id
        });
    }
    
    professionalPhoneClicked(id) {
        this.create({
            eventName: 'professional-phone-clicked',
            professional: id
        });
    }

}