import Auth from 'Base/classes/Auth';
import Api from 'Base/classes/Api';
import Errors from 'Base/classes/Errors';
import Conversion from 'Base/classes/Conversion';

window.ConversationModal = (url) => {
    return {
        url: url,
        opened: false,
        step: 'conversation',
        successMessage: '',
        form: null,
        user: null,
        init()
        {
            this.form = {
                conversation: {
                    email: '',
                    title: '',
                    text: 'Bonjour,',
                    errors: new Errors(),
                },
                login: {
                    email: '',
                    password: '',
                    errors: new Errors(),
                },
                register: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    errors: new Errors(),
                },
                phone: {
                    number: '',
                    token: '',
                    smsSent: false,
                    errors: new Errors(),
                },
                loading: false
            };
            this.step = 'conversation';
            this.successMessage = '';
        },
        open() 
        {
            this.init();
            this.opened = true;
        },
        close()
        {
            this.user = null;
            this.opened = false;
        },
        submit()
        {
            this.form.loading = true;
            this.form.conversation.errors.clear();

            if ( window.current.user ) {
                return this.validate()
                    .then((response) => {
                        this.authed();
                    })
                    .catch(({data}) => this.fail(data.errors));
            }
            
            this.validate()
                .then((response) => {
                    new Auth().checkEmail(this.form.conversation.email)
                        .then(({exists}) => {
                            this.form.login.email = this.form.register.email = this.form.conversation.email;
                            this.step = exists ? 'login' : 'register';
                            this.form.loading = false;
                        });
                })
                .catch(({data}) => this.fail(data.errors));
        },
        success({conversation, message})
        {
            this.form.loading = false;
            this.successMessage = message;
            this.step = 'success';

            new Conversion().newConversation(conversation);

            setTimeout(() => {
                this.close();
            }, 6000);
        },
        fail(errors)
        {
            this.form[this.step].errors.record(errors);
            this.form.loading = false
        },
        getData(step = this.step)
        {
            return this.form[step];
        },
        validate()
        {
            return new Api().post(`${this.url}/validate`, this.getData());
        },
        store()
        {
            new Api().post(this.url, this.getData('conversation'))
                .then((response) => this.success(response));
        },
        login()
        {
            this.form.loading = true;

            new Auth().login(this.form.login)
                .then(response => {
                    this.authed(response.user);
                })
                .catch(({data}) => this.fail(data.errors));
        },
        register()
        {
            this.form.loading = true;

            new Auth().register(this.form.register)
                .then(response => {
                    this.authed(response.user);
                })
                .catch(({data}) => this.fail(data.errors));
        },
        authed(user = window.current.user)
        {            
            this.user = user;

            if ( this.user.is_confirmed_by_phone ) {
                return this.store();
            }

            this.form.loading = false;

            if ( ! this.user.phone ) {
                return this.step = 'set-phone';
            }
            
            this.step = 'confirm-phone';
        },
        addPhoneNumber()
        {
            this.form.loading = true;

            new Auth().addPhoneNumber(this.form.phone.number)
                .then((response) => {
                    this.user = window.current.user = response.user;
                    this.step = 'confirm-phone';
                    this.sendSMS();
                })
        },
        sendSMS()
        {
            this.form.loading = true;

            new Auth().sendConfirmationSMS()
                .then(() => {
                    this.form.phone.smsSent = true;
                    this.form.loading = false;
                })
        },
        confirmPhoneNumber()
        {
            this.form.loading = true;

            new Auth().confirmPhoneNumber(this.form.phone.token)
                .then(() => {
                    this.store();
                })
                .catch(error => {
                    this.form.phone.errors.record({token: error.data.message})
                    this.form.loading = false;
                });
        }
    }

}