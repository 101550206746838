export default class Facebook {
    
    create({event = 'Lead', data})
    {
        if ( typeof window.fbq == "undefined" ) {
            console.log('Cannot send event to Adwords without fbq');
            return;
        }
        
        window.fbq(
            'trackCustom',
            event,
            data
        );
    }

    newQuote({quote, event, professionalId})
    {
        this.create({
            event: event,
            data: {
                professional: professionalId,
                user: quote.user_id,
                quote: quote.id
            }
        });
    }

    newGlobalQuote({quote, professionalId})
    {
        this.newQuote({
            event: 'NewGlobalQuote',
            quote: quote,
            professionalId: professionalId
        });
    }
    
    newDirectQuote({quote, professionalId}) 
    {
        this.newQuote({
            event: 'NewDirectQuote',
            quote: quote,
            professionalId: professionalId
        });
    }
    
    newConversation(conversation)
    {
        this.create({
            event: 'NewConversation',
            data: {
                from: conversation.from_type + '-' + conversation.from_id,
                to: conversation.to_type + '-' + conversation.to_id,
                conversation: conversation.id
            }
        });
    }
    
    professionalPhoneClicked(id)
    {
        this.create({
            event: 'ProfessionalPhoneClick',
            data: {
                professional: id
            }
        });
    }

}