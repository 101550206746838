import UploadedImage from 'Base/classes/UploadedImage';

window.UserAvatarForm = () => {
    return {
        avatar: null,
        loading: false,
        edit: false,
        cropper: null,
        init() {
        },
        submit() {
            this.loading = true;
            this.avatar = this.cropper.getCroppedCanvas().toDataURL("image/jpeg"); 
            this.$nextTick(() => document.querySelector('.js-user-avatar-form').submit());
        },
        addAvatar(file) {
            this.edit = true;

            new UploadedImage().check(file)
                .then(() => new UploadedImage().get(file))
                .then(({source}) => {
                    this.avatar = source;
                    this.$nextTick(() => {
                        this.cropper = new window.Cropper(document.querySelector('.js-user-avatar-form-avatar-crop'), {
                            initialAspectRatio: 1,
                            aspectRatio: 1
                        });
                    });
                });
        },
    }
}