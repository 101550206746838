export default class ConversationMessage
{
    constructor(conversation, data = {})
    {
        this.conversation = conversation;
        this.data = data;
    }
    
    store()
    {
        const data = new FormData();
        data.append('text', this.data.text);
        for ( let i = 0; i < this.data.images.length; i++ ) {
            data.append('images[]', this.data.images[i].source);
        }
        for ( let i = 0; i < this.data.files.length; i++ ) {
            data.append('files[]', this.data.files[i].file);
        }

        return fetch(`${window.base_url}/${window.current.locale_without_country_code}/account/api/conversations/${this.conversation}/messages`, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: data
        });
    }
}